import PropTypes from "prop-types";
import React, { Suspense, lazy, useEffect } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { connect, useDispatch } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { changeLanguage } from "redux-multilanguage";
import { getNavbarAlerts } from "./redux/actions/userinforActions";
import { getFlatShippingCost } from "./redux/actions/productActions";

// Create
const CreatePage = lazy(() => import("./pages/create/Createpage"));
const CreateResult = lazy(() => import("./pages/create/Createresult"));
// home pages
const HomeFashion = lazy(() => import("./pages/home/Home"));
const HomeFashionTwo = lazy(() => import("./pages/home/Home"));

// shop pages
const ShopPage = lazy(() => import("./pages/shop/Shop"));
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const OTP = lazy(() => import("./pages/other/otp"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Payment = lazy(() => import("./pages/other/Payment"));
const OrderSuccess = lazy(() => import("./pages/other/OrderSuccess"));
const OrderFailed = lazy(() => import("./pages/other/OrderFailed"));
const ResetPassword = lazy(() => import("./pages/other/resetpassword"));
const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = ({ userDetail, getNavbarAlerts, getFlatShippingCost }) => {

  const history = useHistory();
  const dispatch = useDispatch();

  const fetchNavbarAlerts = () => {
    let userId;
    if(userDetail)
      userId = userDetail.id;
    else
      userId = localStorage.cartGuestUserId;

    // if(userId)
      getNavbarAlerts(userId);
    getFlatShippingCost();
  }

  useEffect(() => {
    fetchNavbarAlerts();
  }, [userDetail])

  useEffect(() => {
    if(
      window.location.pathname !== '/'
      && (
        !localStorage?.redux_localstorage_simple
        || JSON.parse(localStorage.redux_localstorage_simple)?.categories?.length === 0
      )
    ) {
      window.location = '/';
    }
  }, [])

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeFashionTwo}
                />

                {/* Create */}

                <Route
                  path={process.env.PUBLIC_URL + "/create"}
                  component={CreatePage}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/create-result"}
                  component={CreateResult}
                />

                {/* Homepages */}
                <Route
                  path={process.env.PUBLIC_URL + "/home-fashion"}
                  component={HomeFashion}
                />
                <Route
                  path={process.env.REACT_APP_LOCAL + "/home-fashion-two"}
                  component={HomeFashionTwo}
                />

                {/* Shop pages */}

                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/shop"}
                >
                  <Redirect to='/shop-grid-standard' />
                </Route>
                <Route
                  path={process.env.PUBLIC_URL + "/shop-grid-standard"}
                  component={ShopGridStandard}
                />

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/profile"}
                  component={MyAccount}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/login"}
                  component={LoginRegister}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/register"}
                  component={LoginRegister}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/compare"}
                  component={Compare}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/payment"}
                  component={Payment}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/order-success"}
                  component={OrderSuccess}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/order-failed"}
                  component={OrderFailed}
                />
                <Route path={process.env.PUBLIC_URL + "/otp"} component={OTP} />

                <Route
                  path={process.env.PUBLIC_URL + "/send-email"}
                  component={OTP}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/reset-password"}
                  component={ResetPassword}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
  userDetail: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.userData.userDetail,
    apiToken: state.userData.userToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNavbarAlerts: (userId) => {
      dispatch(getNavbarAlerts(userId));
    },
    getFlatShippingCost: (userId) => {
      dispatch(getFlatShippingCost(userId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);