export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const SET_CART = "SET_CART";
export const NOTHING = "NOTHING";

export const setCart = (payload) => {
    return (dispatch) => dispatch({
      type: SET_CART,
      payload,
    });
};

//add to cart
export const addToCart = (item, apiToken, userId, isGuest, addToast) => {
  return (dispatch) => dispatch({
    dispatch,
    type: ADD_TO_CART,
    payload: { item, apiToken, userId, isGuest, addToast },
  });
};

// get stock of cart item
export const cartItemStock = (item) => {
  if (item.quantity === 0) {
    return false;
  } else {
    return true;
  }
};
