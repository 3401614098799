import axios from "axios";

export async function LoginApi(data) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/auth/login`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: data,
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetNavbarAlerts(userId) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/auth/get-navbar-alerts`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      userId
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function SignUpApi(data) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/auth/signup`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: data,
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function ConfirmCodeOTPApi(data) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/auth/confirm_code`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: data,
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function ResendOTPApi(data) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/auth/resend_code`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: data,
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function ForgotPasswordApi(data) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/auth/password/forget_request`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {email_or_phone: data},
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function ConfirmPasswordApi(data) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/auth/password/confirm_reset`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      verification_code: data.otp,
      password: data.newPassword
    },
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetAllCategoriesApi(data) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/categories`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: data,
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetAllProductsByCatApi(
  categories,
  searchText,
  color,
  pageNumber,
  sortBy,
  selectedTags,
  selectedFilterAttributes,
  colorFilter,

) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/products/category`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      name: searchText,
      page: pageNumber,
      color,
      categories,
      sortBy,
      selectedTags,
      selectedFilterAttributes,
      colorFilter
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetRandomCategoryProducts(id, currentProductId) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/products/category-random/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      currentProductId
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetProductById(id, chosenCategory) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/products/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      chosenCategory
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}


export async function GetFrameApi(data) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/products/product-frame-pic/${data.id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      w: data.printsize[0],
      h: data.printsize[1],
      frameType: data["Frame Type"],
      frameColor: data["Frame Colors"],
      frameWidth: data["Frame Width"],
      mat: data["Mat"],
      mat_width: data["Mat Width"],
      paper_material: data["Paper Material"],
      customImageUrl: data.customImageUrl,
      getBgImages: true,
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetCanvasApi(data) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/products/product-canvas-pic/${data.id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      w: data.printsize[0],
      h: data.printsize[1],
      canvasWidth: data["Canvas Width"],
      stretcherBar: data["Canvas Wrap"],
      customImageUrl: data.customImageUrl,
      getBgImages: true,
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetCustomMetalPrintApi(data) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/products/product-custom-metal-pic/${data.id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      w: data.printsize[0],
      customImageUrl: data.customImageUrl,
      getBgImages: true,
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function AddProductInCart(data, apiToken, userId, isGuest, addToast) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/carts/add`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
    data: {
      id: data.id,
      userId,
      isGuest,
      variant: '',
      quantity: data.cartData.cartQuantity,
      details: JSON.stringify(data.cartData)
    }
  };

  const PostResponse = await axios(config)
    .then(function (response) {
      addToast("Added to cart", { appearance: "success", autoDismiss: true });
      localStorage.setItem('cartGuestUserId', response.data?.guest_user_id);
      return response;
    })
    .catch(function (error) {
      addToast(`Error adding to cart: ${error.response?.data?.message}`, { appearance: "error", autoDismiss: true });
      return error.response;
    });
  return PostResponse;
}

export async function AddProductInWishlist(data, apiToken, addToast) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/wishlists-add-product`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
    data: {
      product_id: data.id,
      details: JSON.stringify(data.productData)
    }
  };

  const PostResponse = await axios(config)
    .then(function (response) {
      addToast("Added to wishlist", { appearance: "success", autoDismiss: true });
      return response;
    })
    .catch(function (error) {
      addToast(`Error adding to wishlist: ${error.response?.data?.message}`, { appearance: "error", autoDismiss: true });
      return error.response;
    });
  return PostResponse;
}

export async function AddWishlistItemToCart(id, productId, userId, apiToken) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/add-wishlist-item-to-cart/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
    params: {
      productId,
      userId
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function DeleteWishlistItem(id, apiToken) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/wishlists-remove-product/${id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetCartItems(userId, apiToken) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/carts`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
    params: {
      userId,
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetWishlist(apiToken) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/wishlists`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function ChangeCartItemQuantity(userId, apiToken, id, quantity) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/carts/change-quantity`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
    data: { id, userId, quantity }
  };

  const PostResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return PostResponse;
}

export async function DeleteCart(userId, apiToken) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/carts/delete-cart`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
    params: {
      userId
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function DeleteWishlist(apiToken) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/wishlists-remove-all`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetCountryData(country) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/country`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    params: {
      country
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function DeleteTempImage(url) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/products/delete-product-temp-pic`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    params: {
      url
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function CustomerCheckout(data, userId, isGuest, apiToken) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/order/store`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
    data: {
      ...data,
      userId,
      isGuest
    }
  };

  const PostResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return PostResponse;
}

export async function GetFrameOnImageOnBg(data) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/products/product-frame-on-img-on-bg-pic/${data.id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      ...data
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetCoffeeMugPrint(data) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/products/product-coffee-mug-pic/${data.id}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      ...data
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function UploadCustomImage(data) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/products/upload-custom-image`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data
  };

  const PostResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return PostResponse;
}

export async function GetAllCustomImages(tempImgUrl) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/products/all-custom-images`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: {
      tempImgUrl
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetColors() {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/colors`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetProfile(apiToken) {

  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/profile/get-profile`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    }                                                                 
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function UpdateUser(user,apiToken) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/profile/update-user`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
    params: { ...user }
  };

  const PostResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return PostResponse;
}

export async function confrimPassword(password,apiToken) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/profile/confrim-password`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
    params: { password }
  };

  const PostResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return PostResponse;
}

export async function setNewUserPassword(password,newPassword,apiToken) {
  var config = {
    method: "post",
    url: `${process.env.REACT_APP_LOCAL_API}/profile/set-new-password`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: apiToken
    },
    params: { 
      password: password,
      newPassword: newPassword
    }
  };

  const PostResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return PostResponse;
}

export async function GetFaq(name) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/get_faqs`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: { name },
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function confirmOrderPayment(orderToken) {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/order/confirm-order-payment`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    params: { orderToken },
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}

export async function GetFlatShippingCost() {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_LOCAL_API}/order/get-flat-shipping-cost`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const GetResponse = await axios(config)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
  return GetResponse;
}