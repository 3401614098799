 
 
 const ProductData = [
    {
        "id": 1,
        "name": "Product 1",
        "image": require('../assets/img/product/ProductData/1.png'),
        "quantity": 10,
    },
    {
        "id": 2,
        "name": "Product 2",
        "image":require('../assets/img/product/ProductData/3.png'),
        "quantity": 10,
    },
    {
        "id": 3,
        "name": "Product 3",
        "image":require('../assets/img/product/ProductData/2.png'),
        "quantity": 10,
        
    },
    {
        "id": 4,
        "name": "Product 4",
        "image": require('../assets/img/product/ProductData/1.png'),
        "quantity": 0,
    },
    {
        "id": 5,
        "name": "Product 5",
        "image":require('../assets/img/product/ProductData/3.png'),
        "quantity": 10,
    },
    {
        "id": 6,
        "name": "Product 6",
        "image":require('../assets/img/product/ProductData/2.png'),
        "quantity": 0,
    },
    {
        "id": 7,
        "name": "Product 7",
        "image": require('../assets/img/product/ProductData/1.png'),
        "quantity": 10,
    },
    {
        "id": 8,
        "name": "Product 8",
        "image":require('../assets/img/product/ProductData/3.png'),
        "quantity": 10,
    },
    {
        "id": 9,
        "name": "Product 9",
        "image":require('../assets/img/product/ProductData/2.png'),
        "quantity": 10,
    },
    {
        "id": 10,
        "name": "Product 10",
        "image": require('../assets/img/product/ProductData/1.png'),
        "quantity": 10,
    },
    {
        "id": 11,
        "name": "Product 11",
        "image":require('../assets/img/product/ProductData/3.png'),
        "quantity": 10,
    },
    {
        "id": 12,
        "name": "Product 12",
        "image":require('../assets/img/product/ProductData/2.png'),
        "quantity": 10,
    },
    {
        "id": 13,
        "name": "Product 13",
        "image": require('../assets/img/product/ProductData/1.png'),
        "quantity": 10,
    },
    {
        "id": 14,
        "name": "Product 14",
        "image":require('../assets/img/product/ProductData/3.png'),
        "quantity": 10,
    },
    {
        "id": 15,
        "name": "Product 15",
        "image":require('../assets/img/product/ProductData/2.png'),
        "quantity": 10,
    },
    {
        "id": 16,
        "name": "Product 16",
        "image": require('../assets/img/product/ProductData/1.png'),
        "quantity": 10,
    },
    {
        "id": 17,
        "name": "Product 17",
        "image":require('../assets/img/product/ProductData/3.png'),
        "quantity": 10,
    },
    {
        "id": 18,
        "name": "Product 18",
        "image":require('../assets/img/product/ProductData/2.png'),
        "quantity": 10,
    },
    {
        "id": 19,
        "name": "Product 19",
        "image": require('../assets/img/product/ProductData/1.png'),
        "quantity": 10,
    },
    {
        "id": 20,
        "name": "Product 20",
        "image":require('../assets/img/product/ProductData/3.png'),
        "quantity": 10,
    },
    {
        "id": 21,
        "name": "Product 21",
        "image":require('../assets/img/product/ProductData/2.png'),
        "quantity": 10,
    },
    {
        "id": 22,
        "name": "Product 22",
        "image": require('../assets/img/product/ProductData/1.png'),
        "quantity": 10,
    },
    {
        "id": 23,
        "name": "Product 23",
        "image":require('../assets/img/product/ProductData/3.png'),
        "quantity": 10,
    },
    {
        "id": 24,
        "name": "Product 24",
        "image":require('../assets/img/product/ProductData/2.png'),
        "quantity": 10,
    },

   
]

export default ProductData;