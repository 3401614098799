export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";
export const SET_WISHLIST = "SET_WISHLIST";

// add to wishlist
export const addToWishlist = (item, apiToken, userId, addToast) => {
  return dispatch => dispatch({
    dispatch,
    type: ADD_TO_WISHLIST,
    payload: { item, apiToken, userId, addToast }
  });
};

export const setWishlist = (payload) => {
  return (dispatch) => dispatch({
    type: SET_WISHLIST,
    payload,
  });
};

// delete from wishlist
export const deleteFromWishlist = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Removed From Wishlist", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_FROM_WISHLIST, payload: item });
  };
};

//delete all from wishlist
export const deleteAllFromWishlist = addToast => {
  return dispatch => dispatch({ type: DELETE_ALL_FROM_WISHLIST, payload: addToast });
};
