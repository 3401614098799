import { FETCH_PRODUCTS_SUCCESS, CUSTOM_PRODUCT_IMAGE, FLAT_SHIPPING_COST } from "../actions/productActions";

const initState = {
  products: [],
  customProductImage: '',
  flatShippingCost: 0,
};

const productReducer = (state = initState, action) => {
  if (action.type === FETCH_PRODUCTS_SUCCESS) {
    return {
      ...state,
      products: action.payload
    };
  }

  if (action.type === CUSTOM_PRODUCT_IMAGE) {
    return {
      ...state,
      customProductImage: action.payload
    };
  }

  if (action.type === FLAT_SHIPPING_COST) {
    return {
      ...state,
      flatShippingCost: action.payload
    };
  }

  return state;
};

export default productReducer;
