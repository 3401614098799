export const SET_CONTACT_INFO = "SET_CONTACT_INFO";
export const SET_ORDER_INFO = "SET_ORDER_INFO";

export const setContactInfo = (payload) => {
    return (dispatch) => dispatch({
      type: SET_CONTACT_INFO,
      payload,
    });
};

export const setOrderInfo = (payload) => {
  return (dispatch) => dispatch({
    type: SET_ORDER_INFO,
    payload,
  });
};