import { AddProductInWishlist, DeleteWishlist } from "../../helpers/api";
import { getNavbarAlerts } from "../actions/userinforActions";
import {
  ADD_TO_WISHLIST,
  DELETE_FROM_WISHLIST,
  DELETE_ALL_FROM_WISHLIST,
  SET_WISHLIST
} from "../actions/wishlistActions";

const initState = [];

const wishlistReducer = (state = initState, action) => {
  const wishlistItems = state;
  let product = action.payload;

  if (action.type === ADD_TO_WISHLIST) {
    product = action.payload?.item
    let apiToken = action.payload?.apiToken;
    let userId = action.payload?.userId;
    let addToast = action.payload?.addToast;

    AddProductInWishlist(product, apiToken, addToast)
      .then(() => action.dispatch(getNavbarAlerts(userId)));
  }

  if (action.type === DELETE_FROM_WISHLIST) {
    const remainingItems = (wishlistItems, product) =>
      wishlistItems.filter(wishlistItem => wishlistItem.id !== product.id);
    return remainingItems(wishlistItems, product);
  }

  if (action.type === DELETE_ALL_FROM_WISHLIST) {
    
  }

  if(action.type === SET_WISHLIST) {
    return action.payload;
  }

  return wishlistItems;
};

export default wishlistReducer;
