import {
  SET_CONTACT_INFO,
  SET_ORDER_INFO,
} from "../actions/paymentActions";

const initState = {
  contactInfo: {
    shipping: {
      contact: '',
      email: '',
      fullName: '',
      address: '',
      city: '',
      postal: '',
      phone: '',
      // fullName: 'Zoraiz Hassan',
      // contact: 'hzoraiz8@gmail.com',
      // email: 'hzoraiz8@gmail.com',
      // address: 'House of glass, hell',
      // city: 'Lahore',
      // postal: '54782',
      // phone: '03154172440',
    },
    additionalNotes: '',
    giftOrDiscountCode: '',
    paymentMethod: 'abhipay',
  },
  emptyContactInfoSample: {
    shipping: {
      contact: '',
      email: '',
      fullName: '',
      address: '',
      city: '',
      postal: '',
      phone: '',
    },
    additionalNotes: '',
    giftOrDiscountCode: '',
    paymentMethod: 'abhipay',
  },
  orderInfo: null,
};

const paymentReducer = (state = initState, action) => {

  if (action.type === SET_CONTACT_INFO) {
    return {
      ...state,
      contactInfo: action.payload
    };
  }

  if (action.type === SET_ORDER_INFO) {
    return {
      ...state,
      orderInfo: action.payload
    };
  }

  return state;
};

export default paymentReducer;
