import { AddProductInCart, GetCartItems } from "../../helpers/api";
import {
  ADD_TO_CART,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  SET_CART,
} from "../actions/cartActions";
import { getNavbarAlerts } from "../actions/userinforActions";

const initState = [];

const cartReducer = (state = initState, action) => {
  let product = action.payload;

  if (action.type === ADD_TO_CART) {
    product = action.payload?.item
    let apiToken = action.payload?.apiToken;
    let addToast = action.payload?.addToast;
    let userId = action.payload?.userId;
    let isGuest = action.payload?.isGuest;

    AddProductInCart(product, apiToken, userId, isGuest, addToast)
      .then(() => {
        if(!userId)
          userId = localStorage.cartGuestUserId;
        
        action.dispatch(getNavbarAlerts(userId))
      });
  }

  if (action.type === SET_CART) {
    return action.payload;
  }

  return state;
};

export default cartReducer;
